<template>
  <div class="items-start self-stretch bg-white flex flex-col px-0">
    <section
      class="items-start self-stretch flex flex-col mb-[516px] max-md:max-w-full max-md:mr-1 max-md:mb-10"
    >
      <div
        class="items-start flex w-[349px] max-w-full gap-0 self-start max-md:justify-center"
      >
        <div
          @click="personSearch"
          class="justify-between items-start bg-white self-stretch flex w-[162px] max-w-full gap-2.5 px-6 py-2.5 cursor-pointer hover:bg-sky-700"
        >
          <h2
            class="text-center text-sm font-semibold leading-5 tracking-wide self-stretch dynamic_color"
          >
            Add Person
          </h2>
          <a
            href="#"
            class="aspect-square object-contain object-center w-5 overflow-hidden self-stretch max-w-full"
          >
            <plus-symbol :fill="mainColor" />
          </a>
        </div>
        <div class="opacity-10 bg-black self-stretch w-0.5 h-10"></div>
        <div
          @click="companySearch"
          class="justify-between items-start bg-white self-stretch flex w-[185px] max-w-full gap-2.5 px-6 py-2.5 cursor-pointer hover:bg-sky-700"
        >
          <h2
            class="text-center text-sm font-semibold leading-5 tracking-wide dynamic_color"
          >
            Add Company
          </h2>
          <a
            href="#"
            class="aspect-square object-contain object-center w-5 overflow-hidden self-stretch max-w-full"
          >
            <plus-symbol :fill="mainColor" />
          </a>
        </div>
      </div>
      <hr
        class="bg-slate-900 bg-opacity-10 w-full h-px self-start max-md:max-w-full"
      />
    </section>
  </div>
</template>

<script>
import plus from "../../assets/svg/plus.svg";
export default {
  name: "person-company-tab",
  props: {
    mainColor: {
      type: String,
      default: "",
      required: true,
    },
  },
  components: {
    "plus-symbol": plus,
  },
  data() {
    return {};
  },
  methods: {
    personSearch() {
      this.$emit("personSearch", null);
    },
    companySearch() {
      this.$emit("companySearch", null);
    },
  },
};
</script>
